
const initialState = {
  networkError: null
};

// initial state
const state = {
  ...initialState
};

// getters
const getters = {

};

// Mutations
const mutations = {

};

// Actions
const actions = {

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
