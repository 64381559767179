import Es6Promise from 'es6-promise';
import 'core-js/stable';
import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import filters from '@/service/Filters';
import { ComponentLibrary, settings } from '@xsenscloud/xse-styleguide';
import Authentication, { forEnv } from '@xsenscloud/vue-authentication';
import VueAppInsights from 'vue-application-insights';
import i18n from '@/i18n';
import * as Sentry from '@sentry/vue';
import Toasted from 'vue-toasted';
import 'vue-toasted/dist/vue-toasted.min.css';
import '@/service/ApiService';

Es6Promise.polyfill();

if (settings.APPINSIGHTS_INSTRUMENTATIONKEY) {
  Vue.use(VueAppInsights, {
    id: settings.APPINSIGHTS_INSTRUMENTATIONKEY,
    router
  });
}

if (settings.SENTRY_DSN) {
  Sentry.init({
    Vue,
    dsn: settings.SENTRY_DSN,
    logErrors: true,
    environment: settings.ENVIRONMENT || 'development'
  });
}

Vue.config.devtools = settings.ENVIRONMENT !== 'production';
Vue.config.productionTip = false;

Vue.use(ComponentLibrary, {
  i18n
});
Vue.use(filters);
Vue.use(Toasted);
Vue.toasted.register('my_toast', (payload) => {
  if (!payload.message) {
    return '';
  }
  return `<div class="my_toast">
            <span>${payload.message}</span>
          </div>`;
}, {
  type: 'error',
  duration: 5000,
  action: {
    text: ' CLOSE X',
    onClick: (e, toastObject) => {
      toastObject.goAway(0);
    }
  }
});
Vue.use(Authentication, { configuration: forEnv(window.env), store });

const vm = new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
});

vm.$mount('#app');
