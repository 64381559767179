<template>
  <div class="base-layout">
    <action-bar @logoAction="home()" :user-data="userData">
      <template #title>
        {{ $t('app.title') }}
      </template>
      <template #dropdown-user>
        <div>
          <ul>
            <li v-if="isAuthenticated"><a href="#" @click="doLogout()">{{ $t('app.logout')}}</a></li>
          </ul>
          <hr>
          <label>{{ $t('app.language')}}</label>
          <multiselect v-model="$root.$i18n.locale" :options="langs" :showLabels="false" :placeholder="$t('app.language_placeholder')"></multiselect>
        </div>
      </template>
      <template #dropdown-apps>
        <div v-if="isAuthenticated">
          <ul>
            <li v-for="app in applications" :key="app.label">
              <a v-if="app.type === 'portal'"
                 :href="app.url"
                 :class="{bold: isCurrent(app.label)}"
                 :title="$t(app.label)"
              >
                {{ $t(app.label) }}
              </a>
            </li>
          </ul>
        </div>
      </template>
    </action-bar>
    <site-menu v-if="isAuthenticated" />
    <div class="content-container container">
      <router-view/>
    </div>
    <site-footer/>
  </div>
</template>

<script>

import SiteMenu from '@/components/common/SiteMenu';
import { mapGetters } from 'vuex';
import { settings } from "@xsenscloud/xse-styleguide";

export default {
  name: 'BaseLayout',
  components: {
    SiteMenu
  },
  data() {
    return {
      langs: ['en-US', 'nl-NL']
    }
  },
  computed: {
    ...mapGetters('user', [
      'account',
      'applications',
      'portalHome'
    ]),
    ...mapGetters('auth', [
      'isAuthenticated',
    ]),
    userData() {
      if (localStorage.getItem('showToast') == null) {
        localStorage.setItem('showToast', "true");
      }

      if (this.account.roles.organization_admin &&
        localStorage.getItem('showToast') == "true" &&
        settings.ENABLE_GENERIC_NOTIFY === "ON"
      ) {
        localStorage.setItem('showToast', "false");
        this.$toasted.global.my_toast({
            message : settings.GENERIC_NOTIFY_INFO
        });
      }
      return this.isAuthenticated ? this.account : {}
    }
  },
  methods: {
    doLogout() {
      localStorage.removeItem('showToast');
      this.$store.dispatch('auth/logout');
    },
    isCurrent(label) {
      return label.includes('billing');
    },
    home() {
      window.location.href = this.portalHome.url;
    }
  }
}
</script>

<style lang="scss">

.bold {
  font-weight: bold;
}

.base-layout {
  background: $white;
  height: 100%;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;

  .container {
    flex-grow: 2;
  }

  .content-container {
    flex-grow: 2;
    padding-bottom: 110px;
    @include media-breakpoint-down(sm) {
      padding-bottom: 230px;
    }
  }
}
</style>
