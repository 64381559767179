<template>
  <fixed-dialog size="small" centered>
    <portal-gun-svg class="icon" />
    <h2>Aw, jeez</h2>
    <p>Something went wrong.</p>
  </fixed-dialog>
</template>

<script>
import portalGunSvg from '@/assets/icons/Portalgun.svg?inline';

export default {
  name: 'NotFound',
  components: { portalGunSvg }
}
</script>

<style lang="scss" scoped>
  .dialog {
    text-align: center;
  }

  .icon {
    margin-bottom: 30px;
    width: auto;
    height: 64px;
  }

</style>
