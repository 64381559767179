import { formatDate, formatNumber, formatTime } from '@/util/Time';
import i18n, { lookupCurrencyLocale } from '../i18n';

function install(Vue) {
  Vue.filter('formatNumber', function (value, format) {
    return formatNumber(value, format);
  });

  Vue.filter('formatDate', function (value, format) {
    return formatDate(value, format)
  });

  Vue.filter('formatTime', function (value) {
    return formatTime(value);
  });

  /**
   * Use the i18n lib to format currency based on the currency in use not the country locale
   */
  Vue.filter('formatCurrency', function(value, currency) {
    return i18n.n(value, 'currency', lookupCurrencyLocale(currency))
  });
}

export default install
