import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { settings } from '@xsenscloud/xse-styleguide';

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context(
    './locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

/**
 * Map currency to fixed locale for formatting
 * @return {string}
 */
export function lookupCurrencyLocale(currency) {
  if (currency === 'EUR') {
    return 'nl-NL';
  } else {
    return 'en-US';
  }
}

export default new VueI18n({
  locale: settings.I18N_LOCALE || 'en-US',
  fallbackLocale: settings.I18N_FALLBACK_LOCALE || 'en-US',
  messages: loadLocaleMessages(),
  numberFormats: {
    'en-US': {
      currency: {
        style: 'currency',
        currency: 'USD'
      }
    },
    'nl-NL': {
      currency: {
        style: 'currency',
        currency: 'EUR'
      }
    }
  }
});
