<template>
  <div id="app">
    <router-view/>
    <vue-snotify/>
  </div>
</template>

<script>
export default {
  name: 'Billing',
}
</script>

<style lang="scss">
@import "scss/app";
</style>
