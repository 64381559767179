<template>
  <div class="site-menu">
    <div class="container">
      <router-link :to="{name: 'licenses-overview'}">
        <svg class="icon icon-xsens_1_summary"><use xlink:href="#icon-xsens_1_summary"/></svg>
        <span>{{ $t('menu.licenses') }}</span>
      </router-link>
      <router-link :to="{name: 'invoices-overview'}">
        <svg class="icon icon-xsens_1_summary"><use xlink:href="#icon-xsens_1_summary"/></svg>
        <span>{{ $t('menu.invoices') }}</span>
      </router-link>
      <router-link :to="{name: 'billing-profile-overview'}" v-if="!billingAddress.has_parent">
        <svg class="icon icon-xsens_1_summary"><use xlink:href="#icon-xsens_1_summary"/></svg>
        <span>{{ $t('menu.profile') }}</span>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SiteMenu',
  computed: {
    ...mapGetters('user', [
      'isSystemAdmin'
    ]),
    ...mapGetters('checkout', [
      'billingAddress'
    ]),
  },
}
</script>

<style lang="scss" scoped>
  $menu-background: $gray-200;
  $menu-color: #1f201d;
  $menu-icon-fill: #1f201d;

.site-menu {
  background: $menu-background;

  .container {
    display: flex;
    flex-direction: row;
    //justify-items: center;

    @include media-breakpoint-down(sm) {
      padding: 0;
      justify-content: space-evenly;
    }
  }

  .icon {
    width: 24px;
    height: 24px;
    fill: $menu-icon-fill;
  }

  a {
    color: $menu-color;
    font-family: $font-family-sans-serif;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
    padding: 8px 8px 8px 0;
    display: flex;
    align-items: center;
    position: relative;
    transition: color 0.2s;
    margin-right: 50px;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      color: $black;
    }

    &:after {
      content: "";
      display: block;
      background: $coral;
      width: 100%;
      height: 3px;
      position: absolute;
      bottom: 0;
      transform: scaleX(0);
      transition: transform 0.25s;
      left: 0;
    }

    @include media-breakpoint-down(xs) {
      padding: 8px 2px;
      margin-right: 20px;
    }

    span {
      margin-left: 7px;
    }

    @include media-breakpoint-down(xs) {
      span {
        margin-left: 2px;
        min-width: auto;
      }
    }
  }

  .router-link-exact-active {
    color: $menu-color;

    &:after {
      transform: scaleX(1);
    }
  }
}
 </style>
