import Vue from "vue";
import axios from "axios";
import { settings } from "@xsenscloud/xse-styleguide";
import store from "@/store";

Vue.prototype.$http = axios.create({
  baseURL: settings.API_URL
});

let currentToken = null;

/**
 * On every request include the Authorization token
 */
Vue.prototype.$http.interceptors.request.use(async config => {
  if (Vue.prototype.$auth.getAccount()) {
    const token = await Vue.prototype.$auth.getAccessToken();
    if (token) {
      currentToken = token;
      config.headers.common["Authorization"] = `Bearer ${token}`;
    }
    const tenant = Vue.prototype.$auth.getTenant();
    if (tenant) {
      config.baseURL = settings.TENANT_API_URL[tenant];
    }
  }
  return config;
});

function refreshToken(store) {
  if (store.state.auth.isRefreshing) {
    return store.state.auth.refreshingCall;
  }
  store.commit("auth/setRefreshingState", true);
  const refreshingCall = Vue.prototype.$auth
    .getAccessToken()
    .then(token => {
      if (currentToken === token) {
        throw new Error(
          "The token was refreshed, but the returned value was equal, so it was valid, something else went wrong."
        );
      }
      return Promise.resolve(token);
    })
    .catch(error => {
      // eslint-disable-next-line no-console
      console.error(error.message);
      throw error;
    })
    .finally(() => {
      store.commit("auth/setRefreshingState", false);
      store.commit("auth/setRefreshingCall", undefined);
    });
  store.commit("auth/setRefreshingCall", refreshingCall);
  return refreshingCall;
}

/**
 * On every response check the error status and logout when a 401 is encountered
 */
Vue.prototype.$http.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      return refreshToken(store)
        .then(token => {
          error.config.headers["Authorization"] = `Bearer ${token}`;
          error.config.baseURL = undefined;
          return Vue.prototype.$http.request(error.config);
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.error(err.message);
          store.dispatch("auth/logout");
        });
    }
    return Promise.reject(error);
  }
);
