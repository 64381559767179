/* eslint-disable no-unused-vars */
import { settings } from '@xsenscloud/xse-styleguide';

const initialState = {
  account: {
    id: null,
    email: null,
    name: '',
    sub: '',
    tenant: '',
    organization: null,
    avatar: null,
    token: null,
    roles: {
      system_admin: false,
      organization_admin: false
    },
    permissions: []
  },
  configuration: []
};

// initial state
const state = {
  ...initialState
};

// getters
const getters = {
  applications: (state, getters) => {
    return settings.APPLICATIONS.filter(config => {
      if (config.permissions.length === 0 && config.roles === 0) {
        return true;
      } else {
        const hasPermissions = config.permissions.reduce(
          (granted, permisson) => {
            return (
              getters.account.permissions.indexOf(permisson) !== -1 && granted
            );
          },
          true
        );

        let hasRole = false;

        if (
          (!config.roles.system_admin && !config.roles.organization_admin) ||
          (config.roles.system_admin && getters.account.roles.system_admin) ||
          (config.roles.organization_admin &&
            getters.account.roles.organization_admin)
        ) {
          hasRole = true;
        }
        return hasPermissions && hasRole;
      }
    });
  },

  portalHome: () => {
    return settings.APPLICATIONS.find(config => config.type === 'portal-home');
  },

  isSystemAdmin: state => {
    return state.account.roles.system_admin;
  },

  isOrganizationAdmin: state => {
    return state.account.roles.organization_admin;
  },

  account: state => {
    return state.account;
  },

  sub: state => {
    return state.account.sub;
  },

  owner: state => {
    return state.account.id;
  }
};

// Mutations
const mutations = {
  clearState(state) {
    state.account = {
      ...initialState.account,
      token: null
    };
  },

  setAccount(state, account) {
    state.account = {
      ...state.account,
      ...account
    };
  },

  setConfiguration(state, configuration) {
    state.configuration = configuration;
  }
};

// Actions
const actions = {
  /**
   * Gets the current account (owner) id
   * @param commit
   * @returns {Promise<void>}
   */
  async currentAccountId({ commit }) {
    // Dummy function
  },

  /**
   * delete token and reset state
   * @param commit
   */
  logout({ commit }) {
    commit('clearState');
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
