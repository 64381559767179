/* eslint-disable no-undef */
import Vue from 'vue';
import Router from 'vue-router';
import BaseLayout from '@/views/layout/Base';
import NotFound from '@/views/NotFound';
import { SignInView } from '@xsenscloud/xse-styleguide';
import redirectWhenAuthenticated from '@/service/redirectWhenAuthenticated';
// import redirectWhenUnauthenticated from '@/service/redirectWhenUnauthenticated';

Vue.use(Router);

const router = new Router({
  mode: process.env.NODE_ENV === 'production' ? 'hash' : 'hash',
  routes: [
    {
      path: '/licenses',
      component: BaseLayout,
      // beforeEnter: redirectWhenUnauthenticated('login', ['organization_admin']),
      children: [
        {
          path: 'overview',
          name: 'licenses-overview',
          component: () =>
            import(
              /* webpackChunkName: "licenses" */ './views/licenses/LicensesOverview.vue'
            )
        }
      ]
    },
    {
      path: '/invoices',
      component: BaseLayout,
      // beforeEnter: redirectWhenUnauthenticated('login', ['organization_admin']),
      children: [
        {
          path: 'overview',
          name: 'invoices-overview',
          component: () =>
            import(
              /* webpackChunkName: "invoices" */ './views/invoices/InvoicesOverview.vue'
            )
        }
      ]
    },

    {
      path: '/profile',
      component: BaseLayout,
      // beforeEnter: redirectWhenUnauthenticated('login', ['organization_admin']),
      children: [
        {
          path: 'billing',
          name: 'billing-profile-overview',
          component: () =>
            import(
              /* webpackChunkName: "invoices" */ './views/profile/BillingProfileOverview.vue'
            )
        }
      ]
    },

    {
      path: '/',
      component: BaseLayout,
      children: [
        {
          path: '',
          name: 'login',
          props: {
            nextRoute: 'licenses-overview'
          },
          component: SignInView,
          beforeEnter: redirectWhenAuthenticated('licenses-overview')
        },
        {
          path: '/register',
          name: 'register',
          component: () =>
            import(/* webpackChunkName: "base" */ './views/user/Register.vue')
        },
        {
          path: '/error',
          name: 'error',
          props: true,
          component: () =>
            import(/* webpackChunkName: "error" */ './views/Error.vue')
        },
        {
          path: '*',
          component: NotFound
        }
      ]
    }
  ]
});

export default router;
