/* eslint-disable no-undef */
import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import user from '@/store/modules/user';
import auth from '@/store/modules/auth';
import global from '@/store/modules/global';
import checkout from '@/store/modules/checkout';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

const store = new Vuex.Store({
  modules: {
    global,
    user,
    auth,
    checkout
  },
  strict: debug,
  plugins: [
    createPersistedState({
      paths: ['user', 'auth', 'checkout']
    })
  ]
});

export default store;
