import moment from 'moment';
import numeral from 'numeral';
import dayjs from 'dayjs';

export function formatTime (value) {
  const duration = moment.duration(value);
  if (duration.isValid()) {
    return `${numeral(duration.minutes()).format('00')}:${numeral(duration.seconds()).format('00')}.${numeral(duration.milliseconds()).format('000')}`
  } else {
    return '-';
  }
}

export function formatDate (value, format) {
  const day = dayjs(value);
  if (day.isValid()) {
    return day.format(format || 'YYYY-MM-DD');
  } else {
    return '-';
  }
}

export function formatNumber (value, format) {
  return numeral(value).format(format || '0');
}
