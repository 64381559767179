/* eslint-disable no-unused-vars */
import Vue from "vue";

const initialState = {
  step: 0,
  isYearly: false,
  plan: "",
  plans: [],
  plansLoading: false,
  products: [],
  productsLoading: false,
  estimates: {},
  subscriptions: undefined,
  paymentSources: [],
  selectedPaymentSource: null,
  chargebeeConfig: {},
  poNumber: "",
  couponInfo: {},
  agreedToTerms: false,
  billingAddress: {
    first_name: "",
    last_name: "",
    email: "",
    company: "",
    vat_number: "",
    has_parent: false,
    phone: "",
    line1: "",
    line2: "",
    line3: "",
    city: "",
    state_code: "",
    state: "",
    zip: "",
    country: ""
  }
};

// initial state
const state = Object.assign({}, initialState);

// getters
const getters = {
  step: state => {
    return state.step;
  },
  isYearly: state => {
    return state.isYearly;
  },
  plan: state => {
    return state.plan;
  },
  plans: state => {
    return state.plans;
  },
  yearlyPlans: state => {
    return state.plans.filter(plan => plan.period_unit === "year");
  },
  monthlyPlans: state => {
    return state.plans.filter(plan => plan.period_unit === "month");
  },
  products: state => {
    return state.products;
  },
  productsLoading: state => {
    return state.productsLoading;
  },
  plansLoading: state => {
    return state.plansLoading;
  },
  subscriptions: state => {
    return state.subscriptions;
  },
  estimates: state => {
    return state.estimates;
  },
  billingAddress: state => {
    return state.billingAddress;
  },
  chargebeeConfig: state => {
    return state.chargebeeConfig;
  },
  paymentSources: state => {
    return state.paymentSources;
  },
  selectedPaymentSource: state => {
    return state.selectedPaymentSource;
  },
  poNumber: state => {
    return state.poNumber;
  },
  agreedToTerms: state => {
    return state.agreedToTerms;
  },
  couponInfo: state => {
    return state.couponInfo;
  }
};

// Mutations
const mutations = {
  clear(state) {
    state.products = [];
    state.estimates = {};
    state.poNumber = initialState.poNumber;
    state.selectedPaymentSource = initialState.selectedPaymentSource;
    state.agreedToTerms = initialState.agreedToTerms;
  },
  subscriptions(state, subscriptions) {
    state.subscriptions = subscriptions;
  },
  plans(state, plans) {
    state.plans = plans;
  },
  setPlansLoading(state, loading) {
    state.plansLoading = loading;
  },
  products(state, products) {
    state.products = products;
  },
  setProductsLoading(state, loading) {
    state.productsLoading = loading;
  },
  estimates(state, estimates) {
    state.estimates = estimates;
  },
  setYearly(state, newValue) {
    state.isYearly = newValue;
  },
  setStep(state, newValue) {
    state.step = newValue;
  },
  plan(state, plan) {
    state.plan = plan;
  },
  resetEstimates(state) {
    state.estimates = {};
  },
  setChargebeeConfig(state, newValue) {
    state.chargebeeConfig = newValue;
  },
  poNumber(state, newValue) {
    state.poNumber = newValue;
  },
  couponInfo(state, newValue) {
    state.couponInfo = newValue;
  },
  setCustomerProfile(state, newValue) {
    state.billingAddress = {
      ...newValue.billing_profile,
      vat_number: newValue.vat_number,
      has_parent: newValue.has_parent
    };
  },
  setPaymentSources(state, newValue) {
    state.paymentSources = newValue;
  },
  setSelectedPaymentSource(state, newValue) {
    state.selectedPaymentSource = newValue;
  },
  setAgreedToTerms(state, newValue) {
    state.agreedToTerms = newValue;
  },
  updateBillingAddressField(state, { field, value }) {
    state.billingAddress = { ...state.billingAddress, [field]: value };
  }
};

const actions = {
  async unsetPlan({ commit }) {
    commit("plan", "");
  },

  async switchPlan({ dispatch, commit }, plan) {
    commit("plan", plan.id);
    commit("resetEstimates");
  },

  async loadPlans({ commit }) {
    commit("setPlansLoading", true);
    const plans = await Vue.prototype.$http
      .get(`/products/plans`)
      .then(res => res.data);
    commit("plans", plans);
    commit("setPlansLoading", false);
  },

  async loadSubscriptions(context) {
    const subscriptions = await Vue.prototype.$http
      .get("/subscriptions")
      .then(res => res.data);

    context.commit("subscriptions", subscriptions);
  },

  async loadProfile({ commit, getters }) {
    const { data: cbData } = await Vue.prototype.$http.get("/profiles/config");
    commit("setChargebeeConfig", cbData);
    const { data } = await Vue.prototype.$http.get("/profiles/billing");
    commit("setCustomerProfile", data);
  },

  async loadSources({ commit }) {
    const { data } = await Vue.prototype.$http.get("/payments/sources");
    commit("setPaymentSources", data);
  },

  async addSource({ commit, state }, paymentIntent) {
    const { data } = await Vue.prototype.$http.post("/payments/sources", {
      payment_intent: paymentIntent.id
    });
    commit("setPaymentSources", [...state.paymentSources, data]);
    return data;
  },

  async estimate({ commit, getters }) {
    let reqObj = {
      plan: getters.plan,
      coupon_ids: []
    };
    if (getters.couponInfo && getters.couponInfo.id) {
      reqObj['coupon_ids'] = [getters.couponInfo.id]
    }
    const { data } = await Vue.prototype.$http.post(`/subscriptions/estimate`, reqObj);
    commit("estimates", data);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
