/* eslint-disable no-unused-vars */
import store from '@/store';
import { AzureB2CService } from '@xsenscloud/vue-authentication';

const initialState = {
  authenticated: false,
  isRefreshing: false,
  refreshingCall: null
};

// initial state
const state = {
  ...initialState
};

// getters
const getters = {
  isAuthenticated: state => state.authenticated
};

// Mutations
const mutations = {
  clearState(state) {
    state.authenticated = initialState.authenticated;
  },
  setAuthenticated(state, authenticated) {
    state.authenticated = authenticated;
  },
  setRefreshingState(state, refreshing) {
    state.isRefreshing = refreshing;
  },
  setRefreshingCall(state, call) {
    state.refreshingCall = call;
  }
};

// Actions
const actions = {
  /**
   * delete token and reset state
   * @param commit
   */
  logout({ commit }) {
    commit('clearState');
    localStorage.removeItem('showToast');
    store.dispatch('user/logout').then(() => {
      AzureB2CService.logout();
    });
  },
  refresh({ commit }) {
    commit('setAuthenticated', false);
    store.dispatch('user/logout');
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
